import { Injectable, Inject } from "@angular/core";
import { DwCustomContextDefinition, DwCustomContextBase, DwSecurityUserService, DwUIMetaDataConfigToken, 
         DwUIMetaDataConfig, DwContextDefinition, DwSecurityTopics, deepCopy, DwQueryMdAttribute, 
         DwAttributeType, DwControlType, DwMenuService, DwMenuParams } from "@devwareapps/devware-cap";
import { AppMetaDataItemNames, ProgramCourseEntity } from '../../../meta-data/app-meta-data.service';
import { combineLatest, Observable, Subscription, of } from 'rxjs';

@DwCustomContextDefinition({
    keyName: 'program-course-context',
    display: 'Load Parameters',
    isGlobal: false,
    parentItem: AppMetaDataItemNames.ProgramCourse
})
@Injectable()
export class ProgramCourseLoadParameterContextService extends DwCustomContextBase {


    constructor(private dwMenuService: DwMenuService,
    ) {
        super();
    }

    isSet : boolean = false;
    pid : number;
    cid : number;
    setupContextDefinition(contextDef: DwContextDefinition) {
        debugger;
        contextDef.noCache = true;
        contextDef.data = {};

        contextDef.getCustomContextFunction = (context: any, contextData: any) => {
            debugger;
            const pc: ProgramCourseEntity = context?.data;
            this.pid = this.isSet ? this.pid : pc?.ProgramId;
            this.cid = this.isSet ? this.cid : pc?.CourseId;
            this.isSet = context?.data != undefined;
            return of({ OriginalProgramId : this.pid, OriginalCourseId: this.cid });
        }

        contextDef.attributes = this.getContextAttribute();
    }

    private getContextAttribute(): DwQueryMdAttribute[] {
        const attributes: DwQueryMdAttribute[] = [];
        this.addProperty(attributes, 'OriginalProgramId', 'Original Program Id', DwAttributeType.Integer, DwControlType.NumericTextBox);
        this.addProperty(attributes, 'OriginalCourseId', 'Original Course Id', DwAttributeType.Integer, DwControlType.NumericTextBox);
        return attributes;
    }


}