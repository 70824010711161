// tslint:disable
import { Injectable } from '@angular/core';

import { DwQuery, DwQueryBuilderGen, DwItem2, DwItemAttributeCollection, DwItemAttribute, DwItemRelationshipCollection, DwItemRelationship, DwItemDetail2, DwOrmData } from '@devwareapps/devware-cap';
import * as EnterpriseMetaData from '@devwareapps/devware-cap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/************************************************
   Generated with the Devware CAP CLI 4.4.47
   Code Gen Name   App
   API Url         https://snhu-test-api.azurewebsites.net/

   Generated By    MSI/mlt
   Generate On     12/13/2024 8:47:27 AM
************************************************/

export enum AppMetaDataItemNames {
    Campus = 'Campus',
    Certification = 'Certification',
    Competency = 'Competency',
    CompetencyMasterRaw = 'CompetencyMasterRaw',
    CompetencyStatus = 'CompetencyStatus',
    CompetencyType = 'CompetencyType',
    CompetencyVertical = 'CompetencyVertical',
    Course = 'Course',
    CourseCampus = 'CourseCampus',
    CourseCompetency = 'CourseCompetency',
    CourseStatus = 'CourseStatus',
    Keyword = 'Keyword',
    Program = 'Program',
    ProgramCampus = 'ProgramCampus',
    ProgramCompentency = 'ProgramCompentency',
    ProgramCompentencyOutcome = 'ProgramCompentencyOutcome',
    ProgramCourse = 'ProgramCourse',
    ProgramOutcome = 'ProgramOutcome',
    ProgramStatus = 'ProgramStatus',
    RoImportLimits = 'roImportLimits',
    Skill = 'Skill',
    SkillCertification = 'SkillCertification',
    SkillCompetency = 'SkillCompetency',
    SkillKeyword = 'SkillKeyword',
    SkillMasterRaw = 'SkillMasterRaw',
    SkillStatus = 'SkillStatus',
    TmpMetaDataLookupTranslation = 'tmpMetaDataLookupTranslation',
    TmpUIConfigBackup = 'tmpUIConfigBackup',
    Vertical = 'Vertical',
}

export enum AppMetaDataLookups {
    ApprovedCompetenciesOrderedByFullCode = 175,
    CampusAllItems = 164,
    CertificationAllItems = 170,
    CompetencyAllItems = 152,
    CompetencyMasterRawAllItems = 179,
    CompetencyStatusAllItems = 156,
    CompetencyTypeAllItems = 153,
    CompetencyVerticalAllItems = 158,
    CourseAllItems = 155,
    CourseCampusAllItems = 168,
    CourseCompetencyAllItems = 159,
    CourseStatusAllItems = 154,
    KeywordAllItems = 176,
    ProgramAllItems = 163,
    ProgramCompetencyWithOutcomes = 178,
    ProgramCampusAllItems = 165,
    ProgramCompetencyAllItems = 160,
    ProgramCompetencyOutcomeAllItems = 161,
    ProgramCourseAllItems = 167,
    ProgramOutcomeAllItems = 166,
    ProgramStatusAllItems = 162,
    RoImportLimitsAllItems = 186,
    SkillAllItems = 171,
    SkillCertificationAllItems = 173,
    SkillCompetencyAllItems = 172,
    SkillKeywordAllItems = 177,
    SkillMasterRawAllItems = 180,
    SkillStatusAllItems = 169,
    TmpMetaDataLookupTranslationAllItems = 181,
    TmpUIConfigBackupAllItems = 182,
    VerticalAllItems = 157,
}


// Generated Classes for MetaData service
export class CampusMetaData implements DwItem2<CampusEntity, CampusMetaDataAttributes,CampusMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=155;
        this.ItemDetail.itemName='Campus';
        this.ItemDetail.keyName='CampusId';
    }

    CreateEntity(): CampusEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CampusQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CampusEntity, CampusMetaDataAttributes,CampusMetaDataRelations>(query, this);
    }
     Attributes = new CampusMetaDataAttributes();
     Relations = new CampusMetaDataRelations();

}


export type CampusQueryBuilder = DwQueryBuilderGen<CampusEntity, CampusMetaDataAttributes,CampusMetaDataRelations>


export class CampusMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'Campus';
    // Attributes
    CampusId = new DwItemAttribute(this.i, 'CampusId');
    Campus = new DwItemAttribute(this.i, 'Campus');
    CampusCode = new DwItemAttribute(this.i, 'CampusCode');
}

class CampusMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Course Campus */
            CourseCampus = new DwItemRelationship('Campus|CourseCampus|CampusId', 'CourseCampus', 'CourseCampus');
            /** Relation: Program Campus */
            ProgramCampus = new DwItemRelationship('Campus|ProgramCampus|CampusId', 'ProgramCampus', 'ProgramCampus');
    };
export class CertificationMetaData implements DwItem2<CertificationEntity, CertificationMetaDataAttributes,CertificationMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=161;
        this.ItemDetail.itemName='Certification';
        this.ItemDetail.keyName='CertificationId';
    }

    CreateEntity(): CertificationEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CertificationQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CertificationEntity, CertificationMetaDataAttributes,CertificationMetaDataRelations>(query, this);
    }
     Attributes = new CertificationMetaDataAttributes();
     Relations = new CertificationMetaDataRelations();

}


export type CertificationQueryBuilder = DwQueryBuilderGen<CertificationEntity, CertificationMetaDataAttributes,CertificationMetaDataRelations>


export class CertificationMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'Certification';
    // Attributes
    CertificationId = new DwItemAttribute(this.i, 'CertificationId');
    CertificationName = new DwItemAttribute(this.i, 'CertificationName');
}

class CertificationMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Skill Certification */
            SkillCertification = new DwItemRelationship('Certification|SkillCertification|CertificationId', 'SkillCertification', 'SkillCertification');
    };
export class CompetencyMetaData implements DwItem2<CompetencyEntity, CompetencyMetaDataAttributes,CompetencyMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=143;
        this.ItemDetail.itemName='Competency';
        this.ItemDetail.keyName='CompetencyId';
    }

    CreateEntity(): CompetencyEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CompetencyQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CompetencyEntity, CompetencyMetaDataAttributes,CompetencyMetaDataRelations>(query, this);
    }
     Attributes = new CompetencyMetaDataAttributes();
     Relations = new CompetencyMetaDataRelations();

}


export type CompetencyQueryBuilder = DwQueryBuilderGen<CompetencyEntity, CompetencyMetaDataAttributes,CompetencyMetaDataRelations>


export class CompetencyMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'Competency';
    // Attributes
    CompetencyId = new DwItemAttribute(this.i, 'CompetencyId');
    CompetencyCode = new DwItemAttribute(this.i, 'CompetencyCode');
    CompetencyLevel = new DwItemAttribute(this.i, 'CompetencyLevel');
    CompetencyNumber = new DwItemAttribute(this.i, 'CompetencyNumber');
    CompetencyAltNumber = new DwItemAttribute(this.i, 'CompetencyAltNumber');
    CompetencyFullCode = new DwItemAttribute(this.i, 'CompetencyFullCode');
    CompetencyTitle = new DwItemAttribute(this.i, 'CompetencyTitle');
    CompetencyStatement = new DwItemAttribute(this.i, 'CompetencyStatement');
    CompetencyStatusId = new DwItemAttribute(this.i, 'CompetencyStatusId');
    CompetencyTypeId = new DwItemAttribute(this.i, 'CompetencyTypeId');
    Subject = new DwItemAttribute(this.i, 'Subject');
}

class CompetencyMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Competency Status */
            CompetencyStatus = new DwItemRelationship('CompetencyStatus|Competency|CompetencyStatusId', 'CompetencyStatus', 'CompetencyStatus');
            /** Relation: Competency Type */
            CompetencyType = new DwItemRelationship('CompetencyType|Competency|CompetencyTypeId', 'CompetencyType', 'CompetencyType');
            /** Relation: Competency Vertical */
            CompetencyVertical = new DwItemRelationship('Competency|CompetencyVertical|CompetencyId', 'CompetencyVertical', 'CompetencyVertical');
            /** Relation: Course Competency */
            CourseCompetency = new DwItemRelationship('Competency|CourseCompetency|CompetencyId', 'CourseCompetency', 'CourseCompetency');
            /** Relation: Program Compentency */
            ProgramCompentency = new DwItemRelationship('Competency|ProgramCompentency|CompentencyId', 'ProgramCompentency', 'ProgramCompentency');
            /** Relation: Skill Competency */
            SkillCompetency = new DwItemRelationship('Competency|SkillCompetency|CompetencyId', 'SkillCompetency', 'SkillCompetency');
    };
export class CompetencyMasterRawMetaData implements DwItem2<CompetencyMasterRawEntity, CompetencyMasterRawMetaDataAttributes,CompetencyMasterRawMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=167;
        this.ItemDetail.itemName='CompetencyMasterRaw';
        this.ItemDetail.keyName='';
    }

    CreateEntity(): CompetencyMasterRawEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CompetencyMasterRawQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CompetencyMasterRawEntity, CompetencyMasterRawMetaDataAttributes,CompetencyMasterRawMetaDataRelations>(query, this);
    }
     Attributes = new CompetencyMasterRawMetaDataAttributes();
     Relations = new CompetencyMasterRawMetaDataRelations();

}


export type CompetencyMasterRawQueryBuilder = DwQueryBuilderGen<CompetencyMasterRawEntity, CompetencyMasterRawMetaDataAttributes,CompetencyMasterRawMetaDataRelations>


export class CompetencyMasterRawMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CompetencyMasterRaw';
    // Attributes
    Status = new DwItemAttribute(this.i, 'Status');
    Type = new DwItemAttribute(this.i, 'Type');
    AltNumber = new DwItemAttribute(this.i, 'AltNumber');
    Number = new DwItemAttribute(this.i, 'Number');
    Subject = new DwItemAttribute(this.i, 'Subject');
    Level = new DwItemAttribute(this.i, 'Level');
    CompCode = new DwItemAttribute(this.i, 'CompCode');
    ShortTitle = new DwItemAttribute(this.i, 'ShortTitle');
    Statement = new DwItemAttribute(this.i, 'Statement');
    CourseCode = new DwItemAttribute(this.i, 'CourseCode');
    CourseTitle = new DwItemAttribute(this.i, 'CourseTitle');
    ProgramCode = new DwItemAttribute(this.i, 'ProgramCode');
    ProgramTitle = new DwItemAttribute(this.i, 'ProgramTitle');
    Vertical = new DwItemAttribute(this.i, 'Vertical');
    RawId = new DwItemAttribute(this.i, 'RawId');
}

class CompetencyMasterRawMetaDataRelations implements DwItemRelationshipCollection {
    };
export class CompetencyStatusMetaData implements DwItem2<CompetencyStatusEntity, CompetencyStatusMetaDataAttributes,CompetencyStatusMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=147;
        this.ItemDetail.itemName='CompetencyStatus';
        this.ItemDetail.keyName='CompetencyStatusId';
    }

    CreateEntity(): CompetencyStatusEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CompetencyStatusQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CompetencyStatusEntity, CompetencyStatusMetaDataAttributes,CompetencyStatusMetaDataRelations>(query, this);
    }
     Attributes = new CompetencyStatusMetaDataAttributes();
     Relations = new CompetencyStatusMetaDataRelations();

}


export type CompetencyStatusQueryBuilder = DwQueryBuilderGen<CompetencyStatusEntity, CompetencyStatusMetaDataAttributes,CompetencyStatusMetaDataRelations>


export class CompetencyStatusMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CompetencyStatus';
    // Attributes
    CompetencyStatusId = new DwItemAttribute(this.i, 'CompetencyStatusId');
    CompetencyStatus = new DwItemAttribute(this.i, 'CompetencyStatus');
}

class CompetencyStatusMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Competency */
            Competency = new DwItemRelationship('CompetencyStatus|Competency|CompetencyStatusId', 'Competency', 'Competency');
    };
export class CompetencyTypeMetaData implements DwItem2<CompetencyTypeEntity, CompetencyTypeMetaDataAttributes,CompetencyTypeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=144;
        this.ItemDetail.itemName='CompetencyType';
        this.ItemDetail.keyName='CompetencyTypeId';
    }

    CreateEntity(): CompetencyTypeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CompetencyTypeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CompetencyTypeEntity, CompetencyTypeMetaDataAttributes,CompetencyTypeMetaDataRelations>(query, this);
    }
     Attributes = new CompetencyTypeMetaDataAttributes();
     Relations = new CompetencyTypeMetaDataRelations();

}


export type CompetencyTypeQueryBuilder = DwQueryBuilderGen<CompetencyTypeEntity, CompetencyTypeMetaDataAttributes,CompetencyTypeMetaDataRelations>


export class CompetencyTypeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CompetencyType';
    // Attributes
    CompetencyTypeId = new DwItemAttribute(this.i, 'CompetencyTypeId');
    CompetencyType = new DwItemAttribute(this.i, 'CompetencyType');
}

class CompetencyTypeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Competency */
            Competency = new DwItemRelationship('CompetencyType|Competency|CompetencyTypeId', 'Competency', 'Competency');
    };
export class CompetencyVerticalMetaData implements DwItem2<CompetencyVerticalEntity, CompetencyVerticalMetaDataAttributes,CompetencyVerticalMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=149;
        this.ItemDetail.itemName='CompetencyVertical';
        this.ItemDetail.keyName='CompetencyVertical';
    }

    CreateEntity(): CompetencyVerticalEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CompetencyVerticalQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CompetencyVerticalEntity, CompetencyVerticalMetaDataAttributes,CompetencyVerticalMetaDataRelations>(query, this);
    }
     Attributes = new CompetencyVerticalMetaDataAttributes();
     Relations = new CompetencyVerticalMetaDataRelations();

}


export type CompetencyVerticalQueryBuilder = DwQueryBuilderGen<CompetencyVerticalEntity, CompetencyVerticalMetaDataAttributes,CompetencyVerticalMetaDataRelations>


export class CompetencyVerticalMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CompetencyVertical';
    // Attributes
    CompetencyVertical = new DwItemAttribute(this.i, 'CompetencyVertical');
    CompetencyId = new DwItemAttribute(this.i, 'CompetencyId');
    VerticalId = new DwItemAttribute(this.i, 'VerticalId');
}

class CompetencyVerticalMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Competency */
            Competency = new DwItemRelationship('Competency|CompetencyVertical|CompetencyId', 'Competency', 'Competency');
            /** Relation: Vertical */
            Vertical = new DwItemRelationship('Vertical|CompetencyVertical|VerticalId', 'Vertical', 'Vertical');
    };
export class CourseMetaData implements DwItem2<CourseEntity, CourseMetaDataAttributes,CourseMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=146;
        this.ItemDetail.itemName='Course';
        this.ItemDetail.keyName='CourseId';
    }

    CreateEntity(): CourseEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CourseQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CourseEntity, CourseMetaDataAttributes,CourseMetaDataRelations>(query, this);
    }
     Attributes = new CourseMetaDataAttributes();
     Relations = new CourseMetaDataRelations();

}


export type CourseQueryBuilder = DwQueryBuilderGen<CourseEntity, CourseMetaDataAttributes,CourseMetaDataRelations>


export class CourseMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'Course';
    // Attributes
    CourseId = new DwItemAttribute(this.i, 'CourseId');
    CourseName = new DwItemAttribute(this.i, 'CourseName');
    CourseStatusId = new DwItemAttribute(this.i, 'CourseStatusId');
    CourseCode = new DwItemAttribute(this.i, 'CourseCode');
    CourseDescription = new DwItemAttribute(this.i, 'CourseDescription');
}

class CourseMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Course Status */
            CourseStatus = new DwItemRelationship('CourseStatus|Course|CourseStatusId', 'CourseStatus', 'CourseStatus');
            /** Relation: Course Campus */
            CourseCampus = new DwItemRelationship('Course|CourseCampus|CourseId', 'CourseCampus', 'CourseCampus');
            /** Relation: Course Competency */
            CourseCompetency = new DwItemRelationship('Course|CourseCompetency|CourseId', 'CourseCompetency', 'CourseCompetency');
            /** Relation: Program Course */
            ProgramCourse = new DwItemRelationship('Course|ProgramCourse|CourseId', 'ProgramCourse', 'ProgramCourse');
    };
export class CourseCampusMetaData implements DwItem2<CourseCampusEntity, CourseCampusMetaDataAttributes,CourseCampusMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=159;
        this.ItemDetail.itemName='CourseCampus';
        this.ItemDetail.keyName='CourseCampusId';
    }

    CreateEntity(): CourseCampusEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CourseCampusQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CourseCampusEntity, CourseCampusMetaDataAttributes,CourseCampusMetaDataRelations>(query, this);
    }
     Attributes = new CourseCampusMetaDataAttributes();
     Relations = new CourseCampusMetaDataRelations();

}


export type CourseCampusQueryBuilder = DwQueryBuilderGen<CourseCampusEntity, CourseCampusMetaDataAttributes,CourseCampusMetaDataRelations>


export class CourseCampusMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CourseCampus';
    // Attributes
    CourseCampusId = new DwItemAttribute(this.i, 'CourseCampusId');
    CourseId = new DwItemAttribute(this.i, 'CourseId');
    CampusId = new DwItemAttribute(this.i, 'CampusId');
}

class CourseCampusMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Campus */
            Campus = new DwItemRelationship('Campus|CourseCampus|CampusId', 'Campus', 'Campus');
            /** Relation: Course */
            Course = new DwItemRelationship('Course|CourseCampus|CourseId', 'Course', 'Course');
    };
export class CourseCompetencyMetaData implements DwItem2<CourseCompetencyEntity, CourseCompetencyMetaDataAttributes,CourseCompetencyMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=150;
        this.ItemDetail.itemName='CourseCompetency';
        this.ItemDetail.keyName='CourseCompetencyId';
    }

    CreateEntity(): CourseCompetencyEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CourseCompetencyQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CourseCompetencyEntity, CourseCompetencyMetaDataAttributes,CourseCompetencyMetaDataRelations>(query, this);
    }
     Attributes = new CourseCompetencyMetaDataAttributes();
     Relations = new CourseCompetencyMetaDataRelations();

}


export type CourseCompetencyQueryBuilder = DwQueryBuilderGen<CourseCompetencyEntity, CourseCompetencyMetaDataAttributes,CourseCompetencyMetaDataRelations>


export class CourseCompetencyMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CourseCompetency';
    // Attributes
    CourseCompetencyId = new DwItemAttribute(this.i, 'CourseCompetencyId');
    CourseId = new DwItemAttribute(this.i, 'CourseId');
    CompetencyId = new DwItemAttribute(this.i, 'CompetencyId');
}

class CourseCompetencyMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Competency */
            Competency = new DwItemRelationship('Competency|CourseCompetency|CompetencyId', 'Competency', 'Competency');
            /** Relation: Course */
            Course = new DwItemRelationship('Course|CourseCompetency|CourseId', 'Course', 'Course');
    };
export class CourseStatusMetaData implements DwItem2<CourseStatusEntity, CourseStatusMetaDataAttributes,CourseStatusMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=145;
        this.ItemDetail.itemName='CourseStatus';
        this.ItemDetail.keyName='CourseStatusId';
    }

    CreateEntity(): CourseStatusEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): CourseStatusQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<CourseStatusEntity, CourseStatusMetaDataAttributes,CourseStatusMetaDataRelations>(query, this);
    }
     Attributes = new CourseStatusMetaDataAttributes();
     Relations = new CourseStatusMetaDataRelations();

}


export type CourseStatusQueryBuilder = DwQueryBuilderGen<CourseStatusEntity, CourseStatusMetaDataAttributes,CourseStatusMetaDataRelations>


export class CourseStatusMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'CourseStatus';
    // Attributes
    CourseStatusId = new DwItemAttribute(this.i, 'CourseStatusId');
    CourseStatus = new DwItemAttribute(this.i, 'CourseStatus');
}

class CourseStatusMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Course */
            Course = new DwItemRelationship('CourseStatus|Course|CourseStatusId', 'Course', 'Course');
    };
export class KeywordMetaData implements DwItem2<KeywordEntity, KeywordMetaDataAttributes,KeywordMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=165;
        this.ItemDetail.itemName='Keyword';
        this.ItemDetail.keyName='KeywordId';
    }

    CreateEntity(): KeywordEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): KeywordQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<KeywordEntity, KeywordMetaDataAttributes,KeywordMetaDataRelations>(query, this);
    }
     Attributes = new KeywordMetaDataAttributes();
     Relations = new KeywordMetaDataRelations();

}


export type KeywordQueryBuilder = DwQueryBuilderGen<KeywordEntity, KeywordMetaDataAttributes,KeywordMetaDataRelations>


export class KeywordMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'Keyword';
    // Attributes
    KeywordId = new DwItemAttribute(this.i, 'KeywordId');
    Keyword = new DwItemAttribute(this.i, 'Keyword');
}

class KeywordMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Skill Keyword */
            SkillKeyword = new DwItemRelationship('Keyword|SkillKeyword|KeywordId', 'SkillKeyword', 'SkillKeyword');
    };
export class ProgramMetaData implements DwItem2<ProgramEntity, ProgramMetaDataAttributes,ProgramMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=154;
        this.ItemDetail.itemName='Program';
        this.ItemDetail.keyName='ProgramId';
    }

    CreateEntity(): ProgramEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): ProgramQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<ProgramEntity, ProgramMetaDataAttributes,ProgramMetaDataRelations>(query, this);
    }
     Attributes = new ProgramMetaDataAttributes();
     Relations = new ProgramMetaDataRelations();

}


export type ProgramQueryBuilder = DwQueryBuilderGen<ProgramEntity, ProgramMetaDataAttributes,ProgramMetaDataRelations>


export class ProgramMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'Program';
    // Attributes
    ProgramId = new DwItemAttribute(this.i, 'ProgramId');
    ProgramTitle = new DwItemAttribute(this.i, 'ProgramTitle');
    DegreeTitle = new DwItemAttribute(this.i, 'DegreeTitle');
    ProgramCode = new DwItemAttribute(this.i, 'ProgramCode');
    ProgramStatusId = new DwItemAttribute(this.i, 'ProgramStatusId');
    ProgramDescription = new DwItemAttribute(this.i, 'ProgramDescription');
    ProgramRequirements = new DwItemAttribute(this.i, 'ProgramRequirements');
}

class ProgramMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Program Status */
            ProgramStatus = new DwItemRelationship('ProgramStatus|Program|ProgramStatusId', 'ProgramStatus', 'ProgramStatus');
            /** Relation: Program Campus */
            ProgramCampus = new DwItemRelationship('Program|ProgramCampus|ProgramId', 'ProgramCampus', 'ProgramCampus');
            /** Relation: Program Compentency */
            ProgramCompentency = new DwItemRelationship('Program|ProgramCompentency|ProgramId', 'ProgramCompentency', 'ProgramCompentency');
            /** Relation: Program Course */
            ProgramCourse = new DwItemRelationship('Program|ProgramCourse|ProgramId', 'ProgramCourse', 'ProgramCourse');
            /** Relation: Program Outcome */
            ProgramOutcome = new DwItemRelationship('Program|ProgramOutcome|ProgramId', 'ProgramOutcome', 'ProgramOutcome');
    };
export class ProgramCampusMetaData implements DwItem2<ProgramCampusEntity, ProgramCampusMetaDataAttributes,ProgramCampusMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=156;
        this.ItemDetail.itemName='ProgramCampus';
        this.ItemDetail.keyName='ProgramCampusId';
    }

    CreateEntity(): ProgramCampusEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): ProgramCampusQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<ProgramCampusEntity, ProgramCampusMetaDataAttributes,ProgramCampusMetaDataRelations>(query, this);
    }
     Attributes = new ProgramCampusMetaDataAttributes();
     Relations = new ProgramCampusMetaDataRelations();

}


export type ProgramCampusQueryBuilder = DwQueryBuilderGen<ProgramCampusEntity, ProgramCampusMetaDataAttributes,ProgramCampusMetaDataRelations>


export class ProgramCampusMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'ProgramCampus';
    // Attributes
    ProgramCampusId = new DwItemAttribute(this.i, 'ProgramCampusId');
    ProgramId = new DwItemAttribute(this.i, 'ProgramId');
    CampusId = new DwItemAttribute(this.i, 'CampusId');
}

class ProgramCampusMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Campus */
            Campus = new DwItemRelationship('Campus|ProgramCampus|CampusId', 'Campus', 'Campus');
            /** Relation: Program */
            Program = new DwItemRelationship('Program|ProgramCampus|ProgramId', 'Program', 'Program');
    };
export class ProgramCompentencyMetaData implements DwItem2<ProgramCompentencyEntity, ProgramCompentencyMetaDataAttributes,ProgramCompentencyMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=151;
        this.ItemDetail.itemName='ProgramCompentency';
        this.ItemDetail.keyName='ProgramCompetencyId';
    }

    CreateEntity(): ProgramCompentencyEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): ProgramCompentencyQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<ProgramCompentencyEntity, ProgramCompentencyMetaDataAttributes,ProgramCompentencyMetaDataRelations>(query, this);
    }
     Attributes = new ProgramCompentencyMetaDataAttributes();
     Relations = new ProgramCompentencyMetaDataRelations();

}


export type ProgramCompentencyQueryBuilder = DwQueryBuilderGen<ProgramCompentencyEntity, ProgramCompentencyMetaDataAttributes,ProgramCompentencyMetaDataRelations>


export class ProgramCompentencyMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'ProgramCompentency';
    // Attributes
    ProgramCompetencyId = new DwItemAttribute(this.i, 'ProgramCompetencyId');
    ProgramId = new DwItemAttribute(this.i, 'ProgramId');
    CompetencyId = new DwItemAttribute(this.i, 'CompetencyId');
}

class ProgramCompentencyMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Competency */
            Competency = new DwItemRelationship('Competency|ProgramCompentency|CompentencyId', 'Competency', 'Competency');
            /** Relation: Program */
            Program = new DwItemRelationship('Program|ProgramCompentency|ProgramId', 'Program', 'Program');
            /** Relation: Program Competency Outcome */
            ProgramCompentencyOutcome = new DwItemRelationship('ProgramCompentency|ProgramCompentencyOutcome|ProgramCompetencyId', 'ProgramCompentencyOutcome', 'ProgramCompentencyOutcome');
    };
export class ProgramCompentencyOutcomeMetaData implements DwItem2<ProgramCompentencyOutcomeEntity, ProgramCompentencyOutcomeMetaDataAttributes,ProgramCompentencyOutcomeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=152;
        this.ItemDetail.itemName='ProgramCompentencyOutcome';
        this.ItemDetail.keyName='ProgramCompetencyOutcomeId';
    }

    CreateEntity(): ProgramCompentencyOutcomeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): ProgramCompentencyOutcomeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<ProgramCompentencyOutcomeEntity, ProgramCompentencyOutcomeMetaDataAttributes,ProgramCompentencyOutcomeMetaDataRelations>(query, this);
    }
     Attributes = new ProgramCompentencyOutcomeMetaDataAttributes();
     Relations = new ProgramCompentencyOutcomeMetaDataRelations();

}


export type ProgramCompentencyOutcomeQueryBuilder = DwQueryBuilderGen<ProgramCompentencyOutcomeEntity, ProgramCompentencyOutcomeMetaDataAttributes,ProgramCompentencyOutcomeMetaDataRelations>


export class ProgramCompentencyOutcomeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'ProgramCompentencyOutcome';
    // Attributes
    ProgramCompetencyOutcomeId = new DwItemAttribute(this.i, 'ProgramCompetencyOutcomeId');
    ProgramCompetencyId = new DwItemAttribute(this.i, 'ProgramCompetencyId');
    ProgramOutcomeId = new DwItemAttribute(this.i, 'ProgramOutcomeId');
}

class ProgramCompentencyOutcomeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Program Compentency */
            ProgramCompentency = new DwItemRelationship('ProgramCompentency|ProgramCompentencyOutcome|ProgramCompetencyId', 'ProgramCompentency', 'ProgramCompentency');
            /** Relation: Program Outcome */
            ProgramOutcome = new DwItemRelationship('ProgramOutcome|ProgramCompentencyOutcome|ProgramOutcomeId', 'ProgramOutcome', 'ProgramOutcome');
    };
export class ProgramCourseMetaData implements DwItem2<ProgramCourseEntity, ProgramCourseMetaDataAttributes,ProgramCourseMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=158;
        this.ItemDetail.itemName='ProgramCourse';
        this.ItemDetail.keyName='ProgramCourseId';
    }

    CreateEntity(): ProgramCourseEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): ProgramCourseQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<ProgramCourseEntity, ProgramCourseMetaDataAttributes,ProgramCourseMetaDataRelations>(query, this);
    }
     Attributes = new ProgramCourseMetaDataAttributes();
     Relations = new ProgramCourseMetaDataRelations();

}


export type ProgramCourseQueryBuilder = DwQueryBuilderGen<ProgramCourseEntity, ProgramCourseMetaDataAttributes,ProgramCourseMetaDataRelations>


export class ProgramCourseMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'ProgramCourse';
    // Attributes
    ProgramCourseId = new DwItemAttribute(this.i, 'ProgramCourseId');
    ProgramId = new DwItemAttribute(this.i, 'ProgramId');
    CourseId = new DwItemAttribute(this.i, 'CourseId');
}

class ProgramCourseMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Course */
            Course = new DwItemRelationship('Course|ProgramCourse|CourseId', 'Course', 'Course');
            /** Relation: Program */
            Program = new DwItemRelationship('Program|ProgramCourse|ProgramId', 'Program', 'Program');
    };
export class ProgramOutcomeMetaData implements DwItem2<ProgramOutcomeEntity, ProgramOutcomeMetaDataAttributes,ProgramOutcomeMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=157;
        this.ItemDetail.itemName='ProgramOutcome';
        this.ItemDetail.keyName='ProgramOutcomeId';
    }

    CreateEntity(): ProgramOutcomeEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): ProgramOutcomeQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<ProgramOutcomeEntity, ProgramOutcomeMetaDataAttributes,ProgramOutcomeMetaDataRelations>(query, this);
    }
     Attributes = new ProgramOutcomeMetaDataAttributes();
     Relations = new ProgramOutcomeMetaDataRelations();

}


export type ProgramOutcomeQueryBuilder = DwQueryBuilderGen<ProgramOutcomeEntity, ProgramOutcomeMetaDataAttributes,ProgramOutcomeMetaDataRelations>


export class ProgramOutcomeMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'ProgramOutcome';
    // Attributes
    ProgramOutcomeId = new DwItemAttribute(this.i, 'ProgramOutcomeId');
    ProgramId = new DwItemAttribute(this.i, 'ProgramId');
    ProgramOutcomeCode = new DwItemAttribute(this.i, 'ProgramOutcomeCode');
    Statement = new DwItemAttribute(this.i, 'Statement');
}

class ProgramOutcomeMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Program */
            Program = new DwItemRelationship('Program|ProgramOutcome|ProgramId', 'Program', 'Program');
            /** Relation: Program Competency Outcome */
            ProgramCompentencyOutcome = new DwItemRelationship('ProgramOutcome|ProgramCompentencyOutcome|ProgramOutcomeId', 'ProgramCompentencyOutcome', 'ProgramCompentencyOutcome');
    };
export class ProgramStatusMetaData implements DwItem2<ProgramStatusEntity, ProgramStatusMetaDataAttributes,ProgramStatusMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=153;
        this.ItemDetail.itemName='ProgramStatus';
        this.ItemDetail.keyName='ProgramStatusId';
    }

    CreateEntity(): ProgramStatusEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): ProgramStatusQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<ProgramStatusEntity, ProgramStatusMetaDataAttributes,ProgramStatusMetaDataRelations>(query, this);
    }
     Attributes = new ProgramStatusMetaDataAttributes();
     Relations = new ProgramStatusMetaDataRelations();

}


export type ProgramStatusQueryBuilder = DwQueryBuilderGen<ProgramStatusEntity, ProgramStatusMetaDataAttributes,ProgramStatusMetaDataRelations>


export class ProgramStatusMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'ProgramStatus';
    // Attributes
    ProgramStatusId = new DwItemAttribute(this.i, 'ProgramStatusId');
    ProgramStatus = new DwItemAttribute(this.i, 'ProgramStatus');
}

class ProgramStatusMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Program */
            Program = new DwItemRelationship('ProgramStatus|Program|ProgramStatusId', 'Program', 'Program');
    };
export class RoImportLimitsMetaData implements DwItem2<RoImportLimitsEntity, RoImportLimitsMetaDataAttributes,RoImportLimitsMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=174;
        this.ItemDetail.itemName='roImportLimits';
        this.ItemDetail.keyName='';
    }

    CreateEntity(): RoImportLimitsEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): RoImportLimitsQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<RoImportLimitsEntity, RoImportLimitsMetaDataAttributes,RoImportLimitsMetaDataRelations>(query, this);
    }
     Attributes = new RoImportLimitsMetaDataAttributes();
     Relations = new RoImportLimitsMetaDataRelations();

}


export type RoImportLimitsQueryBuilder = DwQueryBuilderGen<RoImportLimitsEntity, RoImportLimitsMetaDataAttributes,RoImportLimitsMetaDataRelations>


export class RoImportLimitsMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'roImportLimits';
    // Attributes
    Field = new DwItemAttribute(this.i, 'Field');
    Max = new DwItemAttribute(this.i, 'max');
}

class RoImportLimitsMetaDataRelations implements DwItemRelationshipCollection {
    };
export class SkillMetaData implements DwItem2<SkillEntity, SkillMetaDataAttributes,SkillMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=162;
        this.ItemDetail.itemName='Skill';
        this.ItemDetail.keyName='SkillId';
    }

    CreateEntity(): SkillEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): SkillQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<SkillEntity, SkillMetaDataAttributes,SkillMetaDataRelations>(query, this);
    }
     Attributes = new SkillMetaDataAttributes();
     Relations = new SkillMetaDataRelations();

}


export type SkillQueryBuilder = DwQueryBuilderGen<SkillEntity, SkillMetaDataAttributes,SkillMetaDataRelations>


export class SkillMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'Skill';
    // Attributes
    SkillId = new DwItemAttribute(this.i, 'SkillId');
    SkillName = new DwItemAttribute(this.i, 'SkillName');
    SkillCode = new DwItemAttribute(this.i, 'SkillCode');
    SkillShortTitle = new DwItemAttribute(this.i, 'SkillShortTitle');
    SkillStatement = new DwItemAttribute(this.i, 'SkillStatement');
    SocJobTitle = new DwItemAttribute(this.i, 'SocJobTitle');
    Standards = new DwItemAttribute(this.i, 'Standards');
    OtherSkillTaxonomy = new DwItemAttribute(this.i, 'OtherSkillTaxonomy');
    Employers = new DwItemAttribute(this.i, 'Employers');
    Keywords = new DwItemAttribute(this.i, 'Keywords');
    LightcastOpenSkill = new DwItemAttribute(this.i, 'LightcastOpenSkill');
    SkillStatusId = new DwItemAttribute(this.i, 'SkillStatusId');
}

class SkillMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Skill Status */
            SkillStatus = new DwItemRelationship('SkillStatus|Skill|SkillStatusId', 'SkillStatus', 'SkillStatus');
            /** Relation: Skill Certification */
            SkillCertification = new DwItemRelationship('Skill|SkillCertification|SkillId', 'SkillCertification', 'SkillCertification');
            /** Relation: Skill Competency */
            SkillCompetency = new DwItemRelationship('Skill|SkillCompetency|SkillId', 'SkillCompetency', 'SkillCompetency');
            /** Relation: Skill Keyword */
            SkillKeyword = new DwItemRelationship('Skill|SkillKeyword|SkillId', 'SkillKeyword', 'SkillKeyword');
    };
export class SkillCertificationMetaData implements DwItem2<SkillCertificationEntity, SkillCertificationMetaDataAttributes,SkillCertificationMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=164;
        this.ItemDetail.itemName='SkillCertification';
        this.ItemDetail.keyName='SkillCertificationId';
    }

    CreateEntity(): SkillCertificationEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): SkillCertificationQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<SkillCertificationEntity, SkillCertificationMetaDataAttributes,SkillCertificationMetaDataRelations>(query, this);
    }
     Attributes = new SkillCertificationMetaDataAttributes();
     Relations = new SkillCertificationMetaDataRelations();

}


export type SkillCertificationQueryBuilder = DwQueryBuilderGen<SkillCertificationEntity, SkillCertificationMetaDataAttributes,SkillCertificationMetaDataRelations>


export class SkillCertificationMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'SkillCertification';
    // Attributes
    SkillCertificationId = new DwItemAttribute(this.i, 'SkillCertificationId');
    SkillId = new DwItemAttribute(this.i, 'SkillId');
    CertificationId = new DwItemAttribute(this.i, 'CertificationId');
}

class SkillCertificationMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Certification */
            Certification = new DwItemRelationship('Certification|SkillCertification|CertificationId', 'Certification', 'Certification');
            /** Relation: Skill */
            Skill = new DwItemRelationship('Skill|SkillCertification|SkillId', 'Skill', 'Skill');
    };
export class SkillCompetencyMetaData implements DwItem2<SkillCompetencyEntity, SkillCompetencyMetaDataAttributes,SkillCompetencyMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=163;
        this.ItemDetail.itemName='SkillCompetency';
        this.ItemDetail.keyName='SkillCompetencyId';
    }

    CreateEntity(): SkillCompetencyEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): SkillCompetencyQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<SkillCompetencyEntity, SkillCompetencyMetaDataAttributes,SkillCompetencyMetaDataRelations>(query, this);
    }
     Attributes = new SkillCompetencyMetaDataAttributes();
     Relations = new SkillCompetencyMetaDataRelations();

}


export type SkillCompetencyQueryBuilder = DwQueryBuilderGen<SkillCompetencyEntity, SkillCompetencyMetaDataAttributes,SkillCompetencyMetaDataRelations>


export class SkillCompetencyMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'SkillCompetency';
    // Attributes
    SkillCompetencyId = new DwItemAttribute(this.i, 'SkillCompetencyId');
    SkillId = new DwItemAttribute(this.i, 'SkillId');
    CompetencyId = new DwItemAttribute(this.i, 'CompetencyId');
}

class SkillCompetencyMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Competency */
            Competency = new DwItemRelationship('Competency|SkillCompetency|CompetencyId', 'Competency', 'Competency');
            /** Relation: Skill */
            Skill = new DwItemRelationship('Skill|SkillCompetency|SkillId', 'Skill', 'Skill');
    };
export class SkillKeywordMetaData implements DwItem2<SkillKeywordEntity, SkillKeywordMetaDataAttributes,SkillKeywordMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=166;
        this.ItemDetail.itemName='SkillKeyword';
        this.ItemDetail.keyName='SkillKeywordId';
    }

    CreateEntity(): SkillKeywordEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): SkillKeywordQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<SkillKeywordEntity, SkillKeywordMetaDataAttributes,SkillKeywordMetaDataRelations>(query, this);
    }
     Attributes = new SkillKeywordMetaDataAttributes();
     Relations = new SkillKeywordMetaDataRelations();

}


export type SkillKeywordQueryBuilder = DwQueryBuilderGen<SkillKeywordEntity, SkillKeywordMetaDataAttributes,SkillKeywordMetaDataRelations>


export class SkillKeywordMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'SkillKeyword';
    // Attributes
    SkillKeywordId = new DwItemAttribute(this.i, 'SkillKeywordId');
    SkillId = new DwItemAttribute(this.i, 'SkillId');
    KeywordId = new DwItemAttribute(this.i, 'KeywordId');
}

class SkillKeywordMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Keyword */
            Keyword = new DwItemRelationship('Keyword|SkillKeyword|KeywordId', 'Keyword', 'Keyword');
            /** Relation: Skill */
            Skill = new DwItemRelationship('Skill|SkillKeyword|SkillId', 'Skill', 'Skill');
    };
export class SkillMasterRawMetaData implements DwItem2<SkillMasterRawEntity, SkillMasterRawMetaDataAttributes,SkillMasterRawMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=168;
        this.ItemDetail.itemName='SkillMasterRaw';
        this.ItemDetail.keyName='';
    }

    CreateEntity(): SkillMasterRawEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): SkillMasterRawQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<SkillMasterRawEntity, SkillMasterRawMetaDataAttributes,SkillMasterRawMetaDataRelations>(query, this);
    }
     Attributes = new SkillMasterRawMetaDataAttributes();
     Relations = new SkillMasterRawMetaDataRelations();

}


export type SkillMasterRawQueryBuilder = DwQueryBuilderGen<SkillMasterRawEntity, SkillMasterRawMetaDataAttributes,SkillMasterRawMetaDataRelations>


export class SkillMasterRawMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'SkillMasterRaw';
    // Attributes
    ShortTitle = new DwItemAttribute(this.i, 'Short Title');
    SkillStatement = new DwItemAttribute(this.i, 'Skill Statement');
    CompetencyCode = new DwItemAttribute(this.i, 'Competency Code');
    SkillCode = new DwItemAttribute(this.i, 'Skill Code');
    Course = new DwItemAttribute(this.i, 'Course');
    Program = new DwItemAttribute(this.i, 'Program');
    Keywords = new DwItemAttribute(this.i, 'Keywords');
    Standards = new DwItemAttribute(this.i, 'Standards');
    CertificationsIfApplicable = new DwItemAttribute(this.i, 'Certifications (if applicable)');
    SOCJobTitle = new DwItemAttribute(this.i, 'SOC Job Title');
    EmployersIfApplicable = new DwItemAttribute(this.i, 'Employers (if applicable)');
    EmsiOpenSkillAlignment = new DwItemAttribute(this.i, 'Emsi OpenSkill Alignment');
    F13 = new DwItemAttribute(this.i, 'F13');
    F14 = new DwItemAttribute(this.i, 'F14');
    F15 = new DwItemAttribute(this.i, 'F15');
    F16 = new DwItemAttribute(this.i, 'F16');
    F17 = new DwItemAttribute(this.i, 'F17');
    F18 = new DwItemAttribute(this.i, 'F18');
    F19 = new DwItemAttribute(this.i, 'F19');
    F20 = new DwItemAttribute(this.i, 'F20');
    F21 = new DwItemAttribute(this.i, 'F21');
    F22 = new DwItemAttribute(this.i, 'F22');
    F23 = new DwItemAttribute(this.i, 'F23');
    F24 = new DwItemAttribute(this.i, 'F24');
    F25 = new DwItemAttribute(this.i, 'F25');
    F26 = new DwItemAttribute(this.i, 'F26');
    RawId = new DwItemAttribute(this.i, 'RawId');
}

class SkillMasterRawMetaDataRelations implements DwItemRelationshipCollection {
    };
export class SkillStatusMetaData implements DwItem2<SkillStatusEntity, SkillStatusMetaDataAttributes,SkillStatusMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=160;
        this.ItemDetail.itemName='SkillStatus';
        this.ItemDetail.keyName='SkillStatusId';
    }

    CreateEntity(): SkillStatusEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): SkillStatusQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<SkillStatusEntity, SkillStatusMetaDataAttributes,SkillStatusMetaDataRelations>(query, this);
    }
     Attributes = new SkillStatusMetaDataAttributes();
     Relations = new SkillStatusMetaDataRelations();

}


export type SkillStatusQueryBuilder = DwQueryBuilderGen<SkillStatusEntity, SkillStatusMetaDataAttributes,SkillStatusMetaDataRelations>


export class SkillStatusMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'SkillStatus';
    // Attributes
    SkillStatusId = new DwItemAttribute(this.i, 'SkillStatusId');
    SkillStatus = new DwItemAttribute(this.i, 'SkillStatus');
}

class SkillStatusMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Skill */
            Skill = new DwItemRelationship('SkillStatus|Skill|SkillStatusId', 'Skill', 'Skill');
    };
export class TmpMetaDataLookupTranslationMetaData implements DwItem2<TmpMetaDataLookupTranslationEntity, TmpMetaDataLookupTranslationMetaDataAttributes,TmpMetaDataLookupTranslationMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=169;
        this.ItemDetail.itemName='tmpMetaDataLookupTranslation';
        this.ItemDetail.keyName='';
    }

    CreateEntity(): TmpMetaDataLookupTranslationEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): TmpMetaDataLookupTranslationQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<TmpMetaDataLookupTranslationEntity, TmpMetaDataLookupTranslationMetaDataAttributes,TmpMetaDataLookupTranslationMetaDataRelations>(query, this);
    }
     Attributes = new TmpMetaDataLookupTranslationMetaDataAttributes();
     Relations = new TmpMetaDataLookupTranslationMetaDataRelations();

}


export type TmpMetaDataLookupTranslationQueryBuilder = DwQueryBuilderGen<TmpMetaDataLookupTranslationEntity, TmpMetaDataLookupTranslationMetaDataAttributes,TmpMetaDataLookupTranslationMetaDataRelations>


export class TmpMetaDataLookupTranslationMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'tmpMetaDataLookupTranslation';
    // Attributes
    MetaDataLookupId = new DwItemAttribute(this.i, 'MetaDataLookupId');
    LookupName = new DwItemAttribute(this.i, 'LookupName');
    LookupMeaning = new DwItemAttribute(this.i, 'LookupMeaning');
    UniqueId = new DwItemAttribute(this.i, 'UniqueId');
    NewMetaDataLookupId = new DwItemAttribute(this.i, 'NewMetaDataLookupId');
    Processed = new DwItemAttribute(this.i, 'Processed');
}

class TmpMetaDataLookupTranslationMetaDataRelations implements DwItemRelationshipCollection {
    };
export class TmpUIConfigBackupMetaData implements DwItem2<TmpUIConfigBackupEntity, TmpUIConfigBackupMetaDataAttributes,TmpUIConfigBackupMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=170;
        this.ItemDetail.itemName='tmpUIConfigBackup';
        this.ItemDetail.keyName='';
    }

    CreateEntity(): TmpUIConfigBackupEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): TmpUIConfigBackupQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<TmpUIConfigBackupEntity, TmpUIConfigBackupMetaDataAttributes,TmpUIConfigBackupMetaDataRelations>(query, this);
    }
     Attributes = new TmpUIConfigBackupMetaDataAttributes();
     Relations = new TmpUIConfigBackupMetaDataRelations();

}


export type TmpUIConfigBackupQueryBuilder = DwQueryBuilderGen<TmpUIConfigBackupEntity, TmpUIConfigBackupMetaDataAttributes,TmpUIConfigBackupMetaDataRelations>


export class TmpUIConfigBackupMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'tmpUIConfigBackup';
    // Attributes
    UiConfigId = new DwItemAttribute(this.i, 'UiConfigId');
    ConfigKey = new DwItemAttribute(this.i, 'ConfigKey');
    ConfigDisplay = new DwItemAttribute(this.i, 'ConfigDisplay');
    UiConfigTypeId = new DwItemAttribute(this.i, 'UiConfigTypeId');
    ConfigJson = new DwItemAttribute(this.i, 'ConfigJson');
    MetaDataItemId = new DwItemAttribute(this.i, 'MetaDataItemId');
    CreatedBy = new DwItemAttribute(this.i, 'CreatedBy');
    CreatedDateTime = new DwItemAttribute(this.i, 'CreatedDateTime');
    ModifiedBy = new DwItemAttribute(this.i, 'ModifiedBy');
    ModifiedDateTime = new DwItemAttribute(this.i, 'ModifiedDateTime');
    UniqueId = new DwItemAttribute(this.i, 'UniqueId');
    IsBuiltIn = new DwItemAttribute(this.i, 'IsBuiltIn');
}

class TmpUIConfigBackupMetaDataRelations implements DwItemRelationshipCollection {
    };
export class VerticalMetaData implements DwItem2<VerticalEntity, VerticalMetaDataAttributes,VerticalMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();

    constructor() {
        this.ItemDetail.itemId=148;
        this.ItemDetail.itemName='Vertical';
        this.ItemDetail.keyName='VerticalId';
    }

    CreateEntity(): VerticalEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilder(query ?: DwQuery): VerticalQueryBuilder  {
           if (!query)
           {
                  query = new DwQuery(this.ItemDetail.itemName);
            }
           return new DwQueryBuilderGen<VerticalEntity, VerticalMetaDataAttributes,VerticalMetaDataRelations>(query, this);
    }
     Attributes = new VerticalMetaDataAttributes();
     Relations = new VerticalMetaDataRelations();

}


export type VerticalQueryBuilder = DwQueryBuilderGen<VerticalEntity, VerticalMetaDataAttributes,VerticalMetaDataRelations>


export class VerticalMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'Vertical';
    // Attributes
    VerticalId = new DwItemAttribute(this.i, 'VerticalId');
    Vertical = new DwItemAttribute(this.i, 'Vertical');
}

class VerticalMetaDataRelations implements DwItemRelationshipCollection {
            /** Relation: Competency Vertical */
            CompetencyVertical = new DwItemRelationship('Vertical|CompetencyVertical|VerticalId', 'CompetencyVertical', 'CompetencyVertical');
    };
// Generated Query Classes for MetaData Service
export class ProgramCompetencyWithCompetencyDetailsQueryMetaData implements DwItem2<ProgramCompetencyWithCompetencyDetailsQueryEntity, ProgramCompetencyWithCompetencyDetailsQueryMetaDataAttributes,ProgramCompentencyMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();
    QueryMeaning = 'ProgramCompetency_with_Competency_Details';

    constructor() {
        this.ItemDetail.itemId=151;
        this.ItemDetail.itemName='ProgramCompentency';
        this.ItemDetail.keyName='ProgramCompetencyId';
    }

    CreateEntity(): ProgramCompetencyWithCompetencyDetailsQueryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilderFromQuery(query: DwQuery): ProgramCompetencyWithCompetencyDetailsQueryBuilder {
        return new DwQueryBuilderGen<ProgramCompetencyWithCompetencyDetailsQueryEntity, ProgramCompetencyWithCompetencyDetailsQueryMetaDataAttributes,ProgramCompentencyMetaDataRelations>(query, this);
    }

    CreateQueryBuilder(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<ProgramCompetencyWithCompetencyDetailsQueryBuilder> {
        return this.CreateQueryBuilderWithMetaData(dwMetaDataService).pipe(map(result => result.queryBuilder));
    }

    CreateQueryBuilderWithMetaData(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<{queryMetaData: EnterpriseMetaData.DwQueryMetaData, queryBuilder: ProgramCompetencyWithCompetencyDetailsQueryBuilder}> {
        return dwMetaDataService.getSavedQueryMetaData(this.ItemDetail.itemName, null, this.QueryMeaning).pipe(map(queryMetaData =>
        {
            return { queryMetaData: queryMetaData, queryBuilder: new DwQueryBuilderGen<ProgramCompetencyWithCompetencyDetailsQueryEntity, ProgramCompetencyWithCompetencyDetailsQueryMetaDataAttributes,ProgramCompentencyMetaDataRelations>(queryMetaData.DataQuery, this) };
        }));
    }
     Attributes = new ProgramCompetencyWithCompetencyDetailsQueryMetaDataAttributes();
     Relations = new ProgramCompentencyMetaDataRelations();

}


export type ProgramCompetencyWithCompetencyDetailsQueryBuilder = DwQueryBuilderGen<ProgramCompetencyWithCompetencyDetailsQueryEntity, ProgramCompetencyWithCompetencyDetailsQueryMetaDataAttributes,ProgramCompentencyMetaDataRelations>


export class ProgramCompetencyWithCompetencyDetailsQueryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'ProgramCompentency';
    // Attributes
    ProgramId = new DwItemAttribute(this.i, 'ProgramId');
    ProgramCompetencyCompetencyCompetencyId = new DwItemAttribute('Competency', 'ProgramCompetencyCompetencyCompetencyId');
    ProgramCompetencyCompetencyCompetencyTitle = new DwItemAttribute('Competency', 'ProgramCompetencyCompetencyCompetencyTitle');
    ProgramCompetencyCompetencyCompetencyStatement = new DwItemAttribute('Competency', 'ProgramCompetencyCompetencyCompetencyStatement');
    ProgramCompetencyCompetencyCompetencyStatusId = new DwItemAttribute('Competency', 'ProgramCompetencyCompetencyCompetencyStatusId');
    ProgramCompetencyCompetencyCompetencyTypeId = new DwItemAttribute('Competency', 'ProgramCompetencyCompetencyCompetencyTypeId');
}
export class ProgramCourseWithCourseDetailsQueryMetaData implements DwItem2<ProgramCourseWithCourseDetailsQueryEntity, ProgramCourseWithCourseDetailsQueryMetaDataAttributes,ProgramCourseMetaDataRelations> {
    ItemDetail: DwItemDetail2 = new DwItemDetail2();
    QueryMeaning = 'ProgramCourse_with_Course_Details';

    constructor() {
        this.ItemDetail.itemId=158;
        this.ItemDetail.itemName='ProgramCourse';
        this.ItemDetail.keyName='ProgramCourseId';
    }

    CreateEntity(): ProgramCourseWithCourseDetailsQueryEntity {
            return {
                _itemName: this.ItemDetail.itemName,
                _keyName: this.ItemDetail.keyName
            };
    }

    CreateQueryBuilderFromQuery(query: DwQuery): ProgramCourseWithCourseDetailsQueryBuilder {
        return new DwQueryBuilderGen<ProgramCourseWithCourseDetailsQueryEntity, ProgramCourseWithCourseDetailsQueryMetaDataAttributes,ProgramCourseMetaDataRelations>(query, this);
    }

    CreateQueryBuilder(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<ProgramCourseWithCourseDetailsQueryBuilder> {
        return this.CreateQueryBuilderWithMetaData(dwMetaDataService).pipe(map(result => result.queryBuilder));
    }

    CreateQueryBuilderWithMetaData(dwMetaDataService: EnterpriseMetaData.DwMetaDataService): Observable<{queryMetaData: EnterpriseMetaData.DwQueryMetaData, queryBuilder: ProgramCourseWithCourseDetailsQueryBuilder}> {
        return dwMetaDataService.getSavedQueryMetaData(this.ItemDetail.itemName, null, this.QueryMeaning).pipe(map(queryMetaData =>
        {
            return { queryMetaData: queryMetaData, queryBuilder: new DwQueryBuilderGen<ProgramCourseWithCourseDetailsQueryEntity, ProgramCourseWithCourseDetailsQueryMetaDataAttributes,ProgramCourseMetaDataRelations>(queryMetaData.DataQuery, this) };
        }));
    }
     Attributes = new ProgramCourseWithCourseDetailsQueryMetaDataAttributes();
     Relations = new ProgramCourseMetaDataRelations();

}


export type ProgramCourseWithCourseDetailsQueryBuilder = DwQueryBuilderGen<ProgramCourseWithCourseDetailsQueryEntity, ProgramCourseWithCourseDetailsQueryMetaDataAttributes,ProgramCourseMetaDataRelations>


export class ProgramCourseWithCourseDetailsQueryMetaDataAttributes implements DwItemAttributeCollection {
    //shortcut to item name - to reduce file size
    private i : string = 'ProgramCourse';
    // Attributes
    ProgramId = new DwItemAttribute(this.i, 'ProgramId');
    CourseCourseId = new DwItemAttribute('Course', 'CourseCourseId');
    CourseCourseName = new DwItemAttribute('Course', 'CourseCourseName');
    CourseCourseCode = new DwItemAttribute('Course', 'CourseCourseCode');
    CourseCourseStatusId = new DwItemAttribute('Course', 'CourseCourseStatusId');
}


// Generated Query Classes for MetaData Service

class MetaDataQueries {
    ProgramCompetencyWithCompetencyDetails : ProgramCompetencyWithCompetencyDetailsQueryMetaData = new ProgramCompetencyWithCompetencyDetailsQueryMetaData();
    ProgramCourseWithCourseDetails : ProgramCourseWithCourseDetailsQueryMetaData = new ProgramCourseWithCourseDetailsQueryMetaData();
}

export class AppMetaData {

    static Campus : CampusMetaData = new CampusMetaData();
    static Certification : CertificationMetaData = new CertificationMetaData();
    static Competency : CompetencyMetaData = new CompetencyMetaData();
    static CompetencyMasterRaw : CompetencyMasterRawMetaData = new CompetencyMasterRawMetaData();
    static CompetencyStatus : CompetencyStatusMetaData = new CompetencyStatusMetaData();
    static CompetencyType : CompetencyTypeMetaData = new CompetencyTypeMetaData();
    static CompetencyVertical : CompetencyVerticalMetaData = new CompetencyVerticalMetaData();
    static Course : CourseMetaData = new CourseMetaData();
    static CourseCampus : CourseCampusMetaData = new CourseCampusMetaData();
    static CourseCompetency : CourseCompetencyMetaData = new CourseCompetencyMetaData();
    static CourseStatus : CourseStatusMetaData = new CourseStatusMetaData();
    static Keyword : KeywordMetaData = new KeywordMetaData();
    static Program : ProgramMetaData = new ProgramMetaData();
    static ProgramCampus : ProgramCampusMetaData = new ProgramCampusMetaData();
    static ProgramCompentency : ProgramCompentencyMetaData = new ProgramCompentencyMetaData();
    static ProgramCompentencyOutcome : ProgramCompentencyOutcomeMetaData = new ProgramCompentencyOutcomeMetaData();
    static ProgramCourse : ProgramCourseMetaData = new ProgramCourseMetaData();
    static ProgramOutcome : ProgramOutcomeMetaData = new ProgramOutcomeMetaData();
    static ProgramStatus : ProgramStatusMetaData = new ProgramStatusMetaData();
    static RoImportLimits : RoImportLimitsMetaData = new RoImportLimitsMetaData();
    static Skill : SkillMetaData = new SkillMetaData();
    static SkillCertification : SkillCertificationMetaData = new SkillCertificationMetaData();
    static SkillCompetency : SkillCompetencyMetaData = new SkillCompetencyMetaData();
    static SkillKeyword : SkillKeywordMetaData = new SkillKeywordMetaData();
    static SkillMasterRaw : SkillMasterRawMetaData = new SkillMasterRawMetaData();
    static SkillStatus : SkillStatusMetaData = new SkillStatusMetaData();
    static TmpMetaDataLookupTranslation : TmpMetaDataLookupTranslationMetaData = new TmpMetaDataLookupTranslationMetaData();
    static TmpUIConfigBackup : TmpUIConfigBackupMetaData = new TmpUIConfigBackupMetaData();
    static Vertical : VerticalMetaData = new VerticalMetaData();
    static Queries = new MetaDataQueries();
}
// Generated Entity Interfaces
export interface CampusEntity extends DwOrmData {
    // Attributes
    CampusId?: number;
    Campus?: string;
    CampusCode?: string;


    // Relations
    CourseCampus? : CourseCampusEntity[];
    ProgramCampus? : ProgramCampusEntity[];
}

export interface CertificationEntity extends DwOrmData {
    // Attributes
    CertificationId?: number;
    CertificationName?: string;


    // Relations
    SkillCertification? : SkillCertificationEntity[];
}

export interface CompetencyEntity extends DwOrmData {
    // Attributes
    CompetencyId?: number;
    CompetencyCode?: string;
    CompetencyLevel?: number;
    CompetencyNumber?: string;
    CompetencyAltNumber?: number;
    CompetencyFullCode?: string;
    CompetencyTitle?: string;
    CompetencyStatement?: string;
    CompetencyStatusId?: number;
    CompetencyTypeId?: number;
    Subject?: string;

    // Display Attributes
    CompetencyStatusIdDisplay?: string;
    CompetencyTypeIdDisplay?: string;

    // Relations
    CompetencyStatus? : CompetencyStatusEntity;
    CompetencyType? : CompetencyTypeEntity;
    CompetencyVertical? : CompetencyVerticalEntity[];
    CourseCompetency? : CourseCompetencyEntity[];
    ProgramCompentency? : ProgramCompentencyEntity[];
    // Duplicate property excluded
    // ProgramCompentency : ProgramCompentencyEntity[];
    SkillCompetency? : SkillCompetencyEntity[];
}

export interface CompetencyMasterRawEntity extends DwOrmData {
    // Attributes
    Status?: string;
    Type?: string;
    AltNumber?: number;
    Number?: number;
    Subject?: string;
    Level?: number;
    CompCode?: string;
    ShortTitle?: string;
    Statement?: string;
    CourseCode?: string;
    CourseTitle?: string;
    ProgramCode?: string;
    ProgramTitle?: string;
    Vertical?: string;
    RawId?: number;


    // Relations
}

export interface CompetencyStatusEntity extends DwOrmData {
    // Attributes
    CompetencyStatusId?: number;
    CompetencyStatus?: string;


    // Relations
    Competency? : CompetencyEntity[];
}

export interface CompetencyTypeEntity extends DwOrmData {
    // Attributes
    CompetencyTypeId?: number;
    CompetencyType?: string;


    // Relations
    Competency? : CompetencyEntity[];
}

export interface CompetencyVerticalEntity extends DwOrmData {
    // Attributes
    CompetencyVertical?: number;
    CompetencyId?: number;
    VerticalId?: number;

    // Display Attributes
    CompetencyIdDisplay?: string;
    VerticalIdDisplay?: string;

    // Relations
    Competency? : CompetencyEntity;
    Vertical? : VerticalEntity;
}

export interface CourseEntity extends DwOrmData {
    // Attributes
    CourseId?: number;
    CourseName?: string;
    CourseStatusId?: number;
    CourseCode?: string;
    CourseDescription?: string;

    // Display Attributes
    CourseStatusIdDisplay?: string;

    // Relations
    CourseStatus? : CourseStatusEntity;
    CourseCampus? : CourseCampusEntity[];
    CourseCompetency? : CourseCompetencyEntity[];
    ProgramCourse? : ProgramCourseEntity[];
}

export interface CourseCampusEntity extends DwOrmData {
    // Attributes
    CourseCampusId?: number;
    CourseId?: number;
    CampusId?: number;

    // Display Attributes
    CourseIdDisplay?: string;
    CampusIdDisplay?: string;

    // Relations
    Campus? : CampusEntity;
    Course? : CourseEntity;
}

export interface CourseCompetencyEntity extends DwOrmData {
    // Attributes
    CourseCompetencyId?: number;
    CourseId?: number;
    CompetencyId?: number;

    // Display Attributes
    CourseIdDisplay?: string;
    CompetencyIdDisplay?: string;

    // Relations
    Competency? : CompetencyEntity;
    Course? : CourseEntity;
}

export interface CourseStatusEntity extends DwOrmData {
    // Attributes
    CourseStatusId?: number;
    CourseStatus?: string;


    // Relations
    Course? : CourseEntity[];
}

export interface KeywordEntity extends DwOrmData {
    // Attributes
    KeywordId?: number;
    Keyword?: string;


    // Relations
    SkillKeyword? : SkillKeywordEntity[];
}

export interface ProgramEntity extends DwOrmData {
    // Attributes
    ProgramId?: number;
    ProgramTitle?: string;
    DegreeTitle?: string;
    ProgramCode?: string;
    ProgramStatusId?: number;
    ProgramDescription?: string;
    ProgramRequirements?: string;

    // Display Attributes
    ProgramStatusIdDisplay?: string;

    // Relations
    ProgramStatus? : ProgramStatusEntity;
    ProgramCampus? : ProgramCampusEntity[];
    ProgramCompentency? : ProgramCompentencyEntity[];
    ProgramCourse? : ProgramCourseEntity[];
    ProgramOutcome? : ProgramOutcomeEntity[];
}

export interface ProgramCampusEntity extends DwOrmData {
    // Attributes
    ProgramCampusId?: number;
    ProgramId?: number;
    CampusId?: number;

    // Display Attributes
    ProgramIdDisplay?: string;
    CampusIdDisplay?: string;

    // Relations
    Campus? : CampusEntity;
    Program? : ProgramEntity;
}

export interface ProgramCompentencyEntity extends DwOrmData {
    // Attributes
    ProgramCompetencyId?: number;
    ProgramId?: number;
    CompetencyId?: number;

    // Display Attributes
    ProgramIdDisplay?: string;
    CompetencyIdDisplay?: string;

    // Relations
    Competency? : CompetencyEntity;
    // Duplicate property excluded
    // Competency : CompetencyEntity;
    Program? : ProgramEntity;
    ProgramCompentencyOutcome? : ProgramCompentencyOutcomeEntity[];
}

export interface ProgramCompentencyOutcomeEntity extends DwOrmData {
    // Attributes
    ProgramCompetencyOutcomeId?: number;
    ProgramCompetencyId?: number;
    ProgramOutcomeId?: number;

    // Display Attributes
    ProgramCompetencyIdDisplay?: string;
    ProgramOutcomeIdDisplay?: string;

    // Relations
    ProgramCompentency? : ProgramCompentencyEntity;
    ProgramOutcome? : ProgramOutcomeEntity;
}

export interface ProgramCourseEntity extends DwOrmData {
    // Attributes
    ProgramCourseId?: number;
    ProgramId?: number;
    CourseId?: number;

    // Display Attributes
    ProgramIdDisplay?: string;
    CourseIdDisplay?: string;

    // Relations
    Course? : CourseEntity;
    Program? : ProgramEntity;
}

export interface ProgramOutcomeEntity extends DwOrmData {
    // Attributes
    ProgramOutcomeId?: number;
    ProgramId?: number;
    ProgramOutcomeCode?: string;
    Statement?: string;

    // Display Attributes
    ProgramIdDisplay?: string;

    // Relations
    Program? : ProgramEntity;
    ProgramCompentencyOutcome? : ProgramCompentencyOutcomeEntity[];
}

export interface ProgramStatusEntity extends DwOrmData {
    // Attributes
    ProgramStatusId?: number;
    ProgramStatus?: string;


    // Relations
    Program? : ProgramEntity[];
}

export interface RoImportLimitsEntity extends DwOrmData {
    // Attributes
    Field?: string;
    max?: number;


    // Relations
}

export interface SkillEntity extends DwOrmData {
    // Attributes
    SkillId?: number;
    SkillName?: string;
    SkillCode?: string;
    SkillShortTitle?: string;
    SkillStatement?: string;
    SocJobTitle?: string;
    Standards?: string;
    OtherSkillTaxonomy?: string;
    Employers?: string;
    Keywords?: string;
    LightcastOpenSkill?: string;
    SkillStatusId?: number;

    // Display Attributes
    SkillStatusIdDisplay?: string;

    // Relations
    SkillStatus? : SkillStatusEntity;
    SkillCertification? : SkillCertificationEntity[];
    SkillCompetency? : SkillCompetencyEntity[];
    SkillKeyword? : SkillKeywordEntity[];
}

export interface SkillCertificationEntity extends DwOrmData {
    // Attributes
    SkillCertificationId?: number;
    SkillId?: number;
    CertificationId?: number;

    // Display Attributes
    SkillIdDisplay?: string;
    CertificationIdDisplay?: string;

    // Relations
    Certification? : CertificationEntity;
    Skill? : SkillEntity;
}

export interface SkillCompetencyEntity extends DwOrmData {
    // Attributes
    SkillCompetencyId?: number;
    SkillId?: number;
    CompetencyId?: number;

    // Display Attributes
    SkillIdDisplay?: string;
    CompetencyIdDisplay?: string;

    // Relations
    Competency? : CompetencyEntity;
    Skill? : SkillEntity;
}

export interface SkillKeywordEntity extends DwOrmData {
    // Attributes
    SkillKeywordId?: number;
    SkillId?: number;
    KeywordId?: number;

    // Display Attributes
    SkillIdDisplay?: string;
    KeywordIdDisplay?: string;

    // Relations
    Keyword? : KeywordEntity;
    Skill? : SkillEntity;
}

export interface SkillMasterRawEntity extends DwOrmData {
    // Attributes
    // Attribute Short Title has invalid characters and can't be included
    // Attribute Skill Statement has invalid characters and can't be included
    // Attribute Competency Code has invalid characters and can't be included
    // Attribute Skill Code has invalid characters and can't be included
    Course?: string;
    Program?: string;
    Keywords?: string;
    Standards?: string;
    // Attribute Certifications (if applicable) has invalid characters and can't be included
    // Attribute SOC Job Title has invalid characters and can't be included
    // Attribute Employers (if applicable) has invalid characters and can't be included
    // Attribute Emsi OpenSkill Alignment has invalid characters and can't be included
    F13?: string;
    F14?: string;
    F15?: string;
    F16?: string;
    F17?: string;
    F18?: string;
    F19?: string;
    F20?: string;
    F21?: string;
    F22?: string;
    F23?: string;
    F24?: string;
    F25?: string;
    F26?: string;
    RawId?: number;


    // Relations
}

export interface SkillStatusEntity extends DwOrmData {
    // Attributes
    SkillStatusId?: number;
    SkillStatus?: string;


    // Relations
    Skill? : SkillEntity[];
}

export interface TmpMetaDataLookupTranslationEntity extends DwOrmData {
    // Attributes
    MetaDataLookupId?: number;
    LookupName?: string;
    LookupMeaning?: string;
    UniqueId?: string;
    NewMetaDataLookupId?: number;
    Processed?: boolean;


    // Relations
}

export interface TmpUIConfigBackupEntity extends DwOrmData {
    // Attributes
    UiConfigId?: number;
    ConfigKey?: string;
    ConfigDisplay?: string;
    UiConfigTypeId?: number;
    ConfigJson?: string;
    MetaDataItemId?: number;
    CreatedBy?: number;
    CreatedDateTime?: string;
    ModifiedBy?: number;
    ModifiedDateTime?: string;
    UniqueId?: string;
    IsBuiltIn?: boolean;


    // Relations
}

export interface VerticalEntity extends DwOrmData {
    // Attributes
    VerticalId?: number;
    Vertical?: string;


    // Relations
    CompetencyVertical? : CompetencyVerticalEntity[];
}

// Generated Query Interfaces
/** Entity for Query Meaning ProgramCompetency_with_Competency_Details  */
export interface ProgramCompetencyWithCompetencyDetailsQueryEntity extends DwOrmData {
    // Attributes
    ProgramId?: number;
    ProgramIdDisplay?: string;
    ProgramCompetencyCompetencyCompetencyId?: number;
    ProgramCompetencyCompetencyCompetencyTitle?: string;
    ProgramCompetencyCompetencyCompetencyStatement?: string;
    ProgramCompetencyCompetencyCompetencyStatusId?: number;
    ProgramCompetencyCompetencyCompetencyStatusIdDisplay?: string;
    ProgramCompetencyCompetencyCompetencyTypeId?: number;
    ProgramCompetencyCompetencyCompetencyTypeIdDisplay?: string;

    // Relations
    Competency? : CompetencyEntity;
    // Duplicate property excluded
    // Competency : CompetencyEntity;
    Program? : ProgramEntity;
    ProgramCompentencyOutcome? : ProgramCompentencyOutcomeEntity[];
}

/** Entity for Query Meaning ProgramCourse_with_Course_Details  */
export interface ProgramCourseWithCourseDetailsQueryEntity extends DwOrmData {
    // Attributes
    ProgramId?: number;
    ProgramIdDisplay?: string;
    CourseCourseId?: number;
    CourseCourseName?: string;
    CourseCourseCode?: string;
    CourseCourseStatusId?: number;
    CourseCourseStatusIdDisplay?: string;

    // Relations
    Course? : CourseEntity;
    Program? : ProgramEntity;
}

    // Lookup Value Enums  {
export enum CourseStatusAllItems {
        Active = 1,
        Inactive = 2,
        Draft = 3,
    }

export enum CompetencyStatusAllItems {
        Approved = 1,
        Deferred = 2,
    }

export enum ProgramStatusAllItems {
        Active = 1,
        Inactive = 2,
        Draft = 3,
    }

export enum SkillStatusAllItems {
        Active = 1,
        Inactive = 2,
        Draft = 3,
    }

export enum SkillAllItems {
        Num1111 = 4,
        Aaaa = 5,
    }

export enum SkillCompetencyAllItems {
        SkillCompetencyId2 = 2,
        SkillCompetencyId3 = 3,
    }

export enum SkillCertificationAllItems {
        SkillCertificationId7 = 7,
        SkillCertificationId8 = 8,
        SkillCertificationId9 = 9,
        SkillCertificationId10 = 10,
        SkillCertificationId11 = 11,
        SkillCertificationId12 = 12,
        SkillCertificationId13 = 13,
        SkillCertificationId14 = 14,
    }

export enum SkillKeywordAllItems {
    }

// Excluding lookup SkillMasterRaw All Items because it contains 1000 items which exceeds the max item setting of 100.  Override in cli config for code gen using ExcludeLookupsMaxCount
export enum CompetencyTypeAllItems {
        CompetencyOffering = 1,
        MappedCompetency = 2,
    }

export enum ProgramCompetencyOutcomeAllItems {
        BAGDMCORE03 = 1,
        // Duplicate property Name found
        BAGDMCORE03_2 = 2,
        // Duplicate property Name found
        BAGDMCORE03_3 = 3,
        // Duplicate property Name found
        BAGDMCORE03_10 = 10,
    }

export enum ProgramOutcomeAllItems {
        BAGDMCORE03 = 1,
    }

export enum ProgramCompetencyWithOutcomes {
        BAGDMCORE03 = 8,
        // Duplicate property Name found
        BAGDMCORE03_9 = 9,
        // Duplicate property Name found
        BAGDMCORE03_10 = 10,
        // Duplicate property Name found
        BAGDMCORE03_17 = 17,
    }




